<template>
  <div v-if="item">
    <v-row>
      <v-col cols="12" sm="6">
        <vx-card-list title="Space" :item="item" :tab="0">
          <v-list-item>
            <v-list-item-title> Id </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item._id)">
              {{ item._id }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Public URL </v-list-item-title>
            <v-list-item-subtitle>
              <a target="_blank" :href="`${artistURL}/${item.accountId.slug}/${item.locationId?.slug}/${item.slug}`">
                {{ `${artistURL}/${item.accountId.slug}/${item.locationId?.slug}/${item.slug}` }}
              </a>
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Name </v-list-item-title>
            <v-list-item-subtitle> {{ item.name }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toView('location', item.locationId?._id)">
            <v-list-item-title> Location </v-list-item-title>
            <v-list-item-subtitle> {{ item.locationId?.name }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toView('account', item.accountId._id)">
            <v-list-item-title> Host profile </v-list-item-title>
            <v-list-item-subtitle>{{ item.accountId?.name }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Status</v-list-item-title>
            <v-list-item-subtitle>
              <dt-venue-status :value="item.status" />
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Visibility</v-list-item-title>
            <v-list-item-subtitle>
              {{ item.visibility | capitalize }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Marketplace commission </v-list-item-title>
            <v-list-item-subtitle>{{ item.commission }}% </v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title> Partners commission </v-list-item-title>
            <v-list-item-subtitle>{{ item.manualPaymentCommission }}% </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list-meta :item="item" />

        <vx-card-list title="Space Types" :item="item" :tab="0">
          <v-list-item
            v-for="(type, index) in item.types"
            :key="index"
            dense
            :to="$toEdit('attribute', type.attributeId)"
          >
            <v-list-item-subtitle> {{ type.attributeName }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list title="Location Types" :item="item" :tab="0">
          <v-list-item
            v-for="(type, index) in item.locationTypes"
            :key="index"
            dense
            :to="$toEdit('attribute', type.attributeId)"
          >
            <v-list-item-subtitle> {{ type.attributeName }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list title="Disciplines" :item="item" :tab="0">
          <v-list-item
            v-for="(type, index) in item.disciplines"
            :key="index"
            dense
            :to="$toEdit('attribute', type.attributeId)"
          >
            <v-list-item-subtitle> {{ type.attributeName }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list title="Activities" :item="item" :tab="0">
          <v-list-item
            v-for="(type, index) in item.activities"
            :key="index"
            dense
            :to="$toEdit('attribute', type.attributeId)"
          >
            <v-list-item-subtitle> {{ type.attributeName }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list v-if="item.childActivities?.length" title="Child activities" :item="item" :tab="0">
          <v-list-item
            v-for="(type, index) in item.childActivities"
            :key="index"
            dense
            :to="$toEdit('attribute', type.attributeId)"
          >
            <v-list-item-subtitle> {{ type.attributeName }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <v-card class="mb-4">
          <v-card-title class="primary--text">
            Photos
            <v-spacer />
            <vx-btn color="primary" icon :to="$toEdit('space', item._id, 1)">
              <v-icon>mdi-square-edit-outline</v-icon>
            </vx-btn>
          </v-card-title>

          <v-card-text>
            <v-responsive :aspect-ratio="16 / 9">
              <v-carousel hide-delimiters height="100%">
                <v-carousel-item v-for="(_, index) in item.media" :key="index">
                  <vx-img rectangle :space="item" :index="index" />
                </v-carousel-item>
              </v-carousel>
            </v-responsive>
          </v-card-text>
        </v-card>

        <vx-card-list title="General" :item="item" :tab="4">
          <v-list-item>
            <v-list-item-title> Dimensions</v-list-item-title>
            <v-list-item-subtitle class="d-flex">
              <div v-for="(value, name) in item.dimension" :key="name" class="mr-2">{{ name }}: {{ value }}</div>
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Maximum number of artists</v-list-item-title>
            <v-list-item-subtitle>{{ item.maxArtists }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>
      </v-col>

      <v-col cols="12" sm="6">
        <vx-card-list title="References" hide-edit :item="item">
          <v-list-item :to="$toList('space', { locationId: item.locationId?._id })">
            <v-list-item-title> This location's spaces </v-list-item-title>
            <v-list-item-subtitle> {{ count.locationSpaces }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('space', { accountId: item.accountId._id })">
            <v-list-item-title> This host's spaces </v-list-item-title>
            <v-list-item-subtitle> {{ count.accountSpaces }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('location', { accountId: item.accountId._id })">
            <v-list-item-title> This host's locations </v-list-item-title>
            <v-list-item-subtitle> {{ count.accountLocations }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('booking', { spaceId: this.$route.params.id })">
            <v-list-item-title> Bookings</v-list-item-title>
            <v-list-item-subtitle>{{ count.bookings || 0 }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('message', { spaceId: this.$route.params.id })">
            <v-list-item-title> Messages</v-list-item-title>
            <v-list-item-subtitle>{{ count.messages || 0 }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('review', { spaceId: this.$route.params.id })">
            <v-list-item-title> Reviews</v-list-item-title>
            <v-list-item-subtitle>{{ count.reviews || 0 }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toList('account', { favorite: this.$route.params.id })">
            <v-list-item-title> Favourites</v-list-item-title>
            <v-list-item-subtitle>{{ count.wishlists || 0 }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list title="Price" :item="item" :tab="2">
          <v-list-item>
            <v-list-item-title> Type </v-list-item-title>
            <v-list-item-subtitle>{{ item.priceModel }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-for="(price, index) in item.prices" :key="index">
            <v-list-item-title>{{ price.type | capitalize }} </v-list-item-title>
            <v-list-item-subtitle> {{ price.value | price }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item v-for="(price, index) in item.roughPrices" :key="index">
            <v-list-item-title>{{ price.type | capitalize }} </v-list-item-title>
            <v-list-item-subtitle> {{ price.min | price }} - {{ price.max | price }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Minimum booking time </v-list-item-title>
            <v-list-item-subtitle>{{ item.minBookingTimes[0] && item.minBookingTimes[0].value }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Minimum days notice</v-list-item-title>
            <v-list-item-subtitle>{{ item.minNoticeDays }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Maximum days notice</v-list-item-title>
            <v-list-item-subtitle>{{ item.maxNoticeDays }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list title="Availability" :item="item" :tab="3">
          <v-list-item v-for="(availability, index) in item.availabilities" :key="index">
            <v-list-item-title>
              {{ availability.day }}
            </v-list-item-title>

            <v-list-item-subtitle>
              <template v-if="availability.enabled">
                <template v-if="availability.all">24 hour</template>

                <template v-for="(slot, slotIndex) in availability.slots" v-else>
                  <div :key="slotIndex">{{ slot.startHour | hour24 }} - {{ slot.endHour | hour24 }}</div>
                </template>
              </template>

              <template v-else>Closed</template>
            </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <!-- <vx-card-list title="Stats" :item="item" hide-edit>
          <v-list-item>
            <v-list-item-title> Created date</v-list-item-title>
            <v-list-item-subtitle>{{ item.createdAt | date }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Last modified</v-list-item-title>
            <v-list-item-subtitle>{{ item.updatedAt | date }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list> -->

        <vx-card-list title="History" :item="item" hide-edit>
          <v-list-item v-for="(value, name) in count.booking" :key="name">
            <v-list-item-title> {{ name | startcase }} </v-list-item-title>
            <v-list-item-subtitle> {{ value }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>
      </v-col>

      <v-col cols="12">
        <vx-card-json :item="item" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { SpaceService } from '@tutti/services';

export default {
  data() {
    return {
      item: null,
      count: {},
    };
  },
  computed: {
    items() {
      return this.item.media?.map((media, index) => this.$getVenueImage(this.item, '400', index));
    },
  },
  created() {
    this.getUser();
    this.getCount();
  },
  methods: {
    async getUser() {
      const response = await SpaceService.getByIdAdmin(this.$route.params.id);
      if (response) {
        this.item = response.data;
        this.$setTitle(this.item.name);
      }
    },
    async getCount() {
      const response = await SpaceService.count(this.$route.params.id);
      if (response) {
        this.count = response.data;
      }
    },
  },
};
</script>
