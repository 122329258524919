var render = function () {
  var _vm$item$locationId, _vm$item$locationId2, _vm$item$locationId3, _vm$item$locationId4, _vm$item$accountId, _vm$item$childActivit, _vm$item$locationId5;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-card-list', {
    attrs: {
      "title": "Space",
      "item": _vm.item,
      "tab": 0
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Id ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item._id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item._id) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Public URL ")]), _c('v-list-item-subtitle', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "".concat(_vm.artistURL, "/").concat(_vm.item.accountId.slug, "/").concat((_vm$item$locationId = _vm.item.locationId) === null || _vm$item$locationId === void 0 ? void 0 : _vm$item$locationId.slug, "/").concat(_vm.item.slug)
    }
  }, [_vm._v(" " + _vm._s("".concat(_vm.artistURL, "/").concat(_vm.item.accountId.slug, "/").concat((_vm$item$locationId2 = _vm.item.locationId) === null || _vm$item$locationId2 === void 0 ? void 0 : _vm$item$locationId2.slug, "/").concat(_vm.item.slug)) + " ")])])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Name ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.name) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toView('location', (_vm$item$locationId3 = _vm.item.locationId) === null || _vm$item$locationId3 === void 0 ? void 0 : _vm$item$locationId3._id)
    }
  }, [_c('v-list-item-title', [_vm._v(" Location ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s((_vm$item$locationId4 = _vm.item.locationId) === null || _vm$item$locationId4 === void 0 ? void 0 : _vm$item$locationId4.name) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toView('account', _vm.item.accountId._id)
    }
  }, [_c('v-list-item-title', [_vm._v(" Host profile ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s((_vm$item$accountId = _vm.item.accountId) === null || _vm$item$accountId === void 0 ? void 0 : _vm$item$accountId.name) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Status")]), _c('v-list-item-subtitle', [_c('dt-venue-status', {
    attrs: {
      "value": _vm.item.status
    }
  })], 1)], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v("Visibility")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("capitalize")(_vm.item.visibility)) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Marketplace commission ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.item.commission) + "% ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Partners commission ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.item.manualPaymentCommission) + "% ")])], 1)], 1), _c('vx-card-list-meta', {
    attrs: {
      "item": _vm.item
    }
  }), _c('vx-card-list', {
    attrs: {
      "title": "Space Types",
      "item": _vm.item,
      "tab": 0
    }
  }, _vm._l(_vm.item.types, function (type, index) {
    return _c('v-list-item', {
      key: index,
      attrs: {
        "dense": "",
        "to": _vm.$toEdit('attribute', type.attributeId)
      }
    }, [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(type.attributeName) + " ")])], 1);
  }), 1), _c('vx-card-list', {
    attrs: {
      "title": "Location Types",
      "item": _vm.item,
      "tab": 0
    }
  }, _vm._l(_vm.item.locationTypes, function (type, index) {
    return _c('v-list-item', {
      key: index,
      attrs: {
        "dense": "",
        "to": _vm.$toEdit('attribute', type.attributeId)
      }
    }, [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(type.attributeName) + " ")])], 1);
  }), 1), _c('vx-card-list', {
    attrs: {
      "title": "Disciplines",
      "item": _vm.item,
      "tab": 0
    }
  }, _vm._l(_vm.item.disciplines, function (type, index) {
    return _c('v-list-item', {
      key: index,
      attrs: {
        "dense": "",
        "to": _vm.$toEdit('attribute', type.attributeId)
      }
    }, [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(type.attributeName) + " ")])], 1);
  }), 1), _c('vx-card-list', {
    attrs: {
      "title": "Activities",
      "item": _vm.item,
      "tab": 0
    }
  }, _vm._l(_vm.item.activities, function (type, index) {
    return _c('v-list-item', {
      key: index,
      attrs: {
        "dense": "",
        "to": _vm.$toEdit('attribute', type.attributeId)
      }
    }, [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(type.attributeName) + " ")])], 1);
  }), 1), (_vm$item$childActivit = _vm.item.childActivities) !== null && _vm$item$childActivit !== void 0 && _vm$item$childActivit.length ? _c('vx-card-list', {
    attrs: {
      "title": "Child activities",
      "item": _vm.item,
      "tab": 0
    }
  }, _vm._l(_vm.item.childActivities, function (type, index) {
    return _c('v-list-item', {
      key: index,
      attrs: {
        "dense": "",
        "to": _vm.$toEdit('attribute', type.attributeId)
      }
    }, [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(type.attributeName) + " ")])], 1);
  }), 1) : _vm._e(), _c('v-card', {
    staticClass: "mb-4"
  }, [_c('v-card-title', {
    staticClass: "primary--text"
  }, [_vm._v(" Photos "), _c('v-spacer'), _c('vx-btn', {
    attrs: {
      "color": "primary",
      "icon": "",
      "to": _vm.$toEdit('space', _vm.item._id, 1)
    }
  }, [_c('v-icon', [_vm._v("mdi-square-edit-outline")])], 1)], 1), _c('v-card-text', [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 16 / 9
    }
  }, [_c('v-carousel', {
    attrs: {
      "hide-delimiters": "",
      "height": "100%"
    }
  }, _vm._l(_vm.item.media, function (_, index) {
    return _c('v-carousel-item', {
      key: index
    }, [_c('vx-img', {
      attrs: {
        "rectangle": "",
        "space": _vm.item,
        "index": index
      }
    })], 1);
  }), 1)], 1)], 1)], 1), _c('vx-card-list', {
    attrs: {
      "title": "General",
      "item": _vm.item,
      "tab": 4
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Dimensions")]), _c('v-list-item-subtitle', {
    staticClass: "d-flex"
  }, _vm._l(_vm.item.dimension, function (value, name) {
    return _c('div', {
      key: name,
      staticClass: "mr-2"
    }, [_vm._v(_vm._s(name) + ": " + _vm._s(value))]);
  }), 0)], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Maximum number of artists")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.item.maxArtists) + " ")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-card-list', {
    attrs: {
      "title": "References",
      "hide-edit": "",
      "item": _vm.item
    }
  }, [_c('v-list-item', {
    attrs: {
      "to": _vm.$toList('space', {
        locationId: (_vm$item$locationId5 = _vm.item.locationId) === null || _vm$item$locationId5 === void 0 ? void 0 : _vm$item$locationId5._id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" This location's spaces ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.count.locationSpaces) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('space', {
        accountId: _vm.item.accountId._id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" This host's spaces ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.count.accountSpaces) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('location', {
        accountId: _vm.item.accountId._id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" This host's locations ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.count.accountLocations) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('booking', {
        spaceId: this.$route.params.id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Bookings")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.bookings || 0) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('message', {
        spaceId: this.$route.params.id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Messages")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.messages || 0) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('review', {
        spaceId: this.$route.params.id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Reviews")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.reviews || 0) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('account', {
        favorite: this.$route.params.id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Favourites")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.wishlists || 0) + " ")])], 1)], 1), _c('vx-card-list', {
    attrs: {
      "title": "Price",
      "item": _vm.item,
      "tab": 2
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Type ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.item.priceModel) + " ")])], 1), _vm._l(_vm.item.prices, function (price, index) {
    return _c('v-list-item', {
      key: index
    }, [_c('v-list-item-title', [_vm._v(_vm._s(_vm._f("capitalize")(price.type)) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("price")(price.value)) + " ")])], 1);
  }), _vm._l(_vm.item.roughPrices, function (price, index) {
    return _c('v-list-item', {
      key: index
    }, [_c('v-list-item-title', [_vm._v(_vm._s(_vm._f("capitalize")(price.type)) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("price")(price.min)) + " - " + _vm._s(_vm._f("price")(price.max)) + " ")])], 1);
  }), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Minimum booking time ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.item.minBookingTimes[0] && _vm.item.minBookingTimes[0].value) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Minimum days notice")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.item.minNoticeDays) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Maximum days notice")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.item.maxNoticeDays) + " ")])], 1)], 2), _c('vx-card-list', {
    attrs: {
      "title": "Availability",
      "item": _vm.item,
      "tab": 3
    }
  }, _vm._l(_vm.item.availabilities, function (availability, index) {
    return _c('v-list-item', {
      key: index
    }, [_c('v-list-item-title', [_vm._v(" " + _vm._s(availability.day) + " ")]), _c('v-list-item-subtitle', [availability.enabled ? [availability.all ? [_vm._v("24 hour")] : _vm._l(availability.slots, function (slot, slotIndex) {
      return [_c('div', {
        key: slotIndex
      }, [_vm._v(_vm._s(_vm._f("hour24")(slot.startHour)) + " - " + _vm._s(_vm._f("hour24")(slot.endHour)))])];
    })] : [_vm._v("Closed")]], 2)], 1);
  }), 1), _c('vx-card-list', {
    attrs: {
      "title": "History",
      "item": _vm.item,
      "hide-edit": ""
    }
  }, _vm._l(_vm.count.booking, function (value, name) {
    return _c('v-list-item', {
      key: name
    }, [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm._f("startcase")(name)) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(value) + " ")])], 1);
  }), 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vx-card-json', {
    attrs: {
      "item": _vm.item
    }
  })], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }